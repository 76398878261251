import React from "react";
const Subscribe = () => {
  return (
    <div class="w-full p-5 mb-8">
      <div class="container flex flex-col xl:flex-row mx-auto px-5 py-8 xl:py-14 text-gray-500 bg-gray-200 rounded-2xl">
        <div class="w-full mb-6 xl:mb-0 sm:text-center">
          <div class="mb-4 text-gray-900 text-3xl font-extrabold">
          2000+ abunəçiyə qoşulun
          </div>

          <div class="text-lg">
          Bilməniz lazım olan hər şeydən xəbərdar olun.
          </div>
        </div>

        <div class="w-full">
          <div class="flex flex-col justify-center sm:flex-row gap-3 w-full">
            <input
              type="text"
              placeholder="E-mail ünvanınızı daxil edin"
              class="sm:w-2/4 h-12 p-3 text-gray-900 border border-solid border-gray-300 rounded-lg shadow"
            />

            <button className="px-12 py-1 rounded-lg bg-black text-white font-normal text-lg">
              Ətraflı
            </button>
          </div>

          {/* <div class="mt-3 text-sm sm:text-center">
            We care about your data in our{" "}
            <u class="cursor-pointer transition-all duration-300 ease-in-out hover:text-gray-700">
              privacy policy
            </u>
            .
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Subscribe;
