import React from "react";
import Team from "../../components/Team";
import Statics from "../../components/Statics";
import Slider from "../../components/Slider";

import { MdOutlineDoubleArrow } from "react-icons/md";
const Home = () => {
  return (
    <div>
      <div class="lg:px-20 md:px-6 px-4 md:py-12 py-8 mb-4 container m-auto">
        <div class="lg:flex items-center justify-between">
          {/* <div class="lg:w-1/3 text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {" "}
              Niyə Biz?
            </h2>
            <p className="mt-4 text-2xl text-gray-500">
              Sənə uyğun olanı Gəl birlikdə tapaq! <br />
              Sola sürüşdürərək əl işlərimizlə tanış ola bilərsiniz
            </p>

            

            <div className="my-8" style={{ textAlign: "-webkit-center" }}>
              <button
                role="button"
                aria-label="view catalogue"
                className="px-8 py-3 rounded-lg bg-[#ffe8c2] text-white font-normal text-xl flex items-center text-white gap-4"
              >
                Kataloqa baxın
                <MdOutlineDoubleArrow style={{ marginTop: "3px" }} />
              </button>
            </div>
          </div> */}
          {/* <div class="lg:w-7/12 lg:mt-0 mt-8">
            <div class="w-full h-full bg-red-200">
              <img
                src="https://i.ibb.co/cbyDY74/pexels-max-vakhtbovych-6782351-1-1.png"
                alt="apartment design"
                class="w-full sm:block hidden"
              />
              <img
                src="https://i.ibb.co/ZVPGjGJ/pexels-max-vakhtbovych-6782351-1.png"
                alt="apartment design"
                class="sm:hidden block w-full"
              />
            </div>
            <div class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 lg:gap-8 gap-6 lg:mt-8 md:mt-6 mt-4">
              <img
                src="https://i.ibb.co/4Jrp5TB/pexels-max-vakhtbovych-6782370-1.png"
                class="w-full"
                alt="kitchen"
              />
              <img
                src="https://i.ibb.co/0Jv3FSy/pexels-max-vakhtbovych-6436799-1-1.png"
                class="w-full"
                alt="sitting room"
              />
            </div>
          </div> */}
          {/* <Slider /> */}
        </div>
      </div>
      <Team />
      <Statics />
    </div>
  );
};

export default Home;
