import React, { useState } from "react";
import Group from "../assets/image/teams/Group.JPG";
import Group1 from "../assets/image/teams/Group1.JPG";
import Group2 from "../assets/image/teams/Group2.JPG";
import Ruhiyye from "../assets/image/teams/Ruhiyye.JPG";
import Nurane from "../assets/image/teams/Nurane.JPG";
import Pervin from "../assets/image/teams/Pervin.JPG";
import Ramile from "../assets/image/teams/Ramile.JPG";
import Xatire from "../assets/image/teams/Xatire.JPG";
import Zerife from "../assets/image/teams/Zerife.JPG";

const teamMembers = [
  {
    imageUrl: Group,
  },
  {
    imageUrl: Group1,
  },
  {
    imageUrl: Group2,
  },
  {
    name: "Ruhiyyə Əliyeva Nadir",
    role: "Təsisçi",
    imageUrl: Ruhiyye,
    details:
      "Ruhiyyə Əliyeva Nadir qızı 1 may 1983-cü il Qubada doğulub.Moda ilə bağlı ilk təhsili “Vakko ESMOD İstanbul Uluslararası Moda Akademisi”-də “Styling(stilistika),Geyimlərin Konstruksiyası və Dizaynı”(Turkiyə,İstanbul) ixtisasları olub.Daha sonra “Aron Factor School”-da “Konstruktor ”(Rusiya, Moskva), “Viki sews”-də “İç geyimlərin konstruksiyası və tikiş texnologiyası”(Rusiya,Moskva), “Ewst Fashion Lab”-da “təkmilləşmiş mulaj-modelləmə”(Gürcüstan, Tbilisi), “Metin Gürsoy”-un “Lüks Marka Yönetimi” proqramlarını bitirib. 13 oktyabr 2019-cu il Qubada “Azerbaijan Fashion School” və 26 iyun 2021-ci il Bakıda “Azerbaijan Fashion Lab” tədris mərkəzlərini təsis edib.O həmçinin “AFL Geyim Üzrə Konstruksiya Və Modelləmə Üsulları” metodikasının və eyniadlı kitabın müəllifidir.Eyni zamanda ”RUH” brendinin təsisçisi və art direktorudur.",
  },
  {
    name: "Pərvin Əyyubova Elman",
    role: "Təlimçi",
    imageUrl: Pervin,
    details:
      "Pərvin Əyyubova Elman qızı 21 aprel 1995-ci ildə Qubada doğulub.Moda ilə bağlı ilk təhsili ” İMA - İstanbul Moda Akademisi”-də “ Rəng interpretasiyası”(Türkiyə,İstanbul) proqramı olub.Daha sonra “Grasser”-da “Texniki eskiz”(Rusiya ,Sankt-Peterburq), “Aron Factor School”-da “Konstruktor ”(Rusiya, Moskva),“Fine craft”(Rusiya,Rostov-Na-Donu)-da “Konstruksiya sənədləşməsi”, “Viki sews”-də “İç geyimlərinin Konstruksiyası və Tikiş texnologiyası”(Rusiya,Moskva) proqramlarını bitirib.2019-cu ildə “Azerbaijan Fashion School”-da- “Dizayn ixtisası üzrə qabiliyyət imtahanına hazırlıq” və “Moda Eskizi” proqramlarınn tədrisi üzrə çalışıb. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “Konstruktor” proqramı üzrə “Təlimçi” vəzifəsində çalışır.O həmçinin “AFL Geyim Üzrə Konstruksiya Və Modelləmə Üsulları” kitabının müəlliflərindən biridir.",
  },
  {
    name: "Xatirə Məmmədzadə Natiq",
    role: "Koordinator-Administrator",
    imageUrl: Xatire,
    details:
      "Xatirə Məmmədzadə Natiq qızı 21 aprel 2003-cü ildə Bakıda doğulub.Moda ilə bağlı təhsili “Azerbaijan Fashion Lab”-da “Moda dizaynı və Texnologiyası” proqramı olub.Hal hazırda “Azerbaijan Fashion Lab AFL AFS MMC”-də “Koordinator-Administrator” vəzifəsində çalışır.",
  },
  {
    name: "Nuranə İbrahimova Vitaliy",
    role: "Təlimçi",
    imageUrl: Nurane,
    details:
      "Nuranə İbrahimova Vitaliy qızı 3 iyun 1985-ci il Bakıda doğulub.Moda ilə bağlı təhsili “Azerbaijan Fashion Lab”-da “Moda Dizaynı və texnologiyası” proqramı olub.(2017-2020)-ci illər “Ev Tekstili” üzrə “Tikiş texnoloqu” vəzifəsində çalışıb.Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “Konstruktor” proqramı üzrə “Təlimçi” vəzifəsində çalışır.",
  },
  {
    name: "Zərifə Ramazanova Eldar",
    role: "Təlimçi",
    imageUrl: Zerife,
    details:
      "Zərifə Ramazova Eldar qızı 17 may 2000-ci il Mingəçevirdə doğulmuşdur.Moda ilə bağlı ilk təhsili “İMA İstanbul Moda Akademisi”-də “Moda Dizaynına Giriş” ixtisası olub.Daha sonra “Bocconi”-də “Moda və Lüks brendlərin idarə edilməsi” ,”Kaliforniya İncəsənət Universiteti”-də “Qrafik Dizaynın Təməli” ,”DesTech”-də “Qrafik və Uİ/UX dizayn” , “Azerbaijan Fashion Lab”-da “Konstruktor tikiş texnoloqu” proqramlarını bitirib.“Mosaic MMC”-də “Twinset” geyim brendinin “Qrafik Dizayneri” vəzifəsində çalışıb. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “ Moda Dizaynı və texnologiyası” üzrə “Təlimçi” vəzifəsində çalışır.",
  },
  {
    name: "Ramilə İsmayılova Zakir",
    role: "Təlimçi",
    imageUrl: Ramile,
    details:
      "Ramilə İsmayılova Zakir qızı 18 yanvar 1999-cu il Bakıda doğulub.(2021-2023)-ci illər “Sophie Couture”-da “Tikiş texnoloqu” vəzifəsində fəaliyyət göstərib. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “Tikiş Texnoloqu” proqramı üzrə “Təlimçi” vəzifəsində çalışır.",
  },
];

export default function Team() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const openModalWithPerson = (person) => {
    setSelectedPerson(person);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedPerson(null);
  };

  return (
    <div>
      {/* Team */}
      <div
        class="flex items-center w-screen min-h-screen"
        // style="font-family: 'Muli', sans-serif;"
      >
        <div class="container ml-auto mr-auto flex flex-wrap items-start">
          <div class="w-full pl-5 lg:pl-2 mb-4 mt-4">
            <h1 class="text-3xl lg:text-4xl text-gray-700 font-extrabold">
              Komandamız
            </h1>
            
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Group} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Group1} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
            </div>
          </div>

          <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Group2} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
            </div>
          </div>
        </div>
      </div>

      {/* Team members */}
      <div
        class="flex items-center w-screen min-h-screen"
        // style="font-family: 'Muli', sans-serif;"
      >
        <div class="container ml-auto mr-auto flex flex-wrap items-start">
          {/* <div class="w-full pl-5 lg:pl-2 mb-4 mt-4">
            <h1 class="text-3xl lg:text-4xl text-gray-700 font-extrabold">
              Komandamız
            </h1>
          </div> */}
          <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Ruhiyye} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-4 bg-white flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none">
                    Ruhiyyə Əliyeva Nadir
                  </h5>
                  <span class="text-xs text-black leading-none">Təsisçi</span>
                </div>
              </div>{" "}
              <button className="mt-4 px-8 py-2 text-black rounded-lg border-4 border-black">
                Daha çox
              </button>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Pervin} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-4 flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none">
                    Pərvin Əyyubova Elman
                  </h5>
                  <span class="text-xs text-black leading-none">Təlimçi</span>
                </div>
              </div>{" "}
              <button className="mt-4 px-8 py-2 text-black rounded-lg border-4 border-black">
                Daha çox
              </button>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Xatire} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-4 flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none">
                    Xatirə Məmmədzadə Natiq
                  </h5>
                  <span class="text-xs text-black leading-none">
                    Koordinator-Administrator
                  </span>
                </div>
              </div>
              <button className="mt-4 px-8 py-2 text-black rounded-lg border-4 border-black">
                Daha çox
              </button>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Nurane} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-4 flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none">
                    Nuranə İbrahimova Vitaliy
                  </h5>
                  <span class="text-xs text-black leading-none">Təlimçi</span>
                </div>
              </div>{" "}
              <button className="mt-4 px-8 py-2 text-black rounded-lg border-4 border-black">
                Daha çox
              </button>
            </div>{" "}
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Zerife} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-4 flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none">
                    Zərifə Ramazanova Eldar
                  </h5>
                  <span class="text-xs text-black leading-none">Təlimçi</span>
                </div>
              </div>{" "}
              <button className="mt-4 px-8 py-2 text-black rounded-lg border-4 border-black">
                Daha çox
              </button>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={Ramile} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-4 flex flex-col">
                <div>
                  <h5 class="text-black white text-2xl font-bold leading-none">
                    Ramilə İsmayılova Zakir
                  </h5>
                  <span class="text-xs text-black leading-none">Təlimçi</span>
                </div>
              </div>
              <button className="mt-4 px-8 py-2 text-black rounded-lg border-4 border-black">
                Daha çox
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
