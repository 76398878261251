import React from 'react'
import Navigation from '../../common/Header/Navigation'
import Footer from '../../common/Footer/Footer'

const Cabinet = () => {
  return (
    <div>
      <Navigation />

      Cabinet

      <Footer />
    </div>
  )
}

export default Cabinet